
import totvs from '../assets/images/totvs.png';
import tablet from '../assets/images/tablet.jpg';
import delivery from '../assets/images/delivery.png';
import web from '../assets/images/web.jpg';
import pd from '../assets/images/pd.png';
import kds from '../assets/images/kds.jpg';
import garcom from '../assets/images/garcom.jpg';
import totem from '../assets/images/totem.jpg';
import fidelidade from '../assets/images/fidelidade.jpg';
import qr from '../assets/images/qr.jpg';
import maqui from '../assets/images/maqui.png';
import prinx from '../assets/images/prinx.jpg';
import catraca from '../assets/images/catraca.jpg';
import ifood from '../assets/images/ifood.png';
import pdv from '../assets/images/pdv.jpg';

const images = {
    totvs,
    tablet,
    delivery,
    web,
    pd,
    kds,
    garcom,
    totem,
    fidelidade,
    qr,
    maqui,
    prinx,
    catraca,
    ifood,
    pdv,
};

export default images;
